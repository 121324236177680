import { RouteSectionProps, useIsRouting } from "@solidjs/router";
import { Show, Suspense } from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { Sidebar } from "~/components/pluto/sidebar";
import { PlutoProvider } from "~/context/pluto";
import useScript from "~/utils/hooks/use_script";
import { ThreeDotLoader } from "~/widgets/button";

export default function (props: RouteSectionProps) {
  const showSidebar = () =>
    props.location.pathname.includes("chat") ||
    props.location.pathname.includes("new") ||
    props.location.pathname.includes("profile");

  const isRouting = useIsRouting();

  useScript(
    "https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js"
  );

  return (
    <Suspense
      fallback={
        <div class="flex h-full w-full items-center justify-center bg-black">
          <ThreeDotLoader color="#fff" />
        </div>
      }
    >
      <PlutoProvider>
        <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
        <div class="flex h-full w-full overflow-hidden bg-black bg-cover bg-no-repeat">
          <Show when={showSidebar()}>
            <Sidebar />
          </Show>
          <Show when={isRouting()}>
            <div class="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60 ">
              <ThreeDotLoader />
            </div>
          </Show>
          {props.children}
        </div>
      </PlutoProvider>
    </Suspense>
  );
}
